import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/node_modules/@ant-design/icons/es/components/AntdIcon.js");
;
import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/ekklesiapp/htdocs/www.ekklesi.app/web-app/public/logo/ekklesiapp_logo.png");
